<template>
  <div class="animated fadeIn">
    <img src="img/construction.png" width="500px" />
  </div>
</template>

<script>
export default {
  name: "QuestsList"
};
</script>
